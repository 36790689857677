<template>
    <main-container>
        <header-container>
            <OPDPackageParticularHospitalHeader />
        </header-container>
        <div style="height: 94vh; max-height: 94vh; padding-bottom: 20px; overflow-y: scroll; scroll-behavior: smooth;" id="scrollableDiv">
                <div id="searchMe" style="display: block;width: 100%; max-width: 425px; position: relative; top: 0; z-index: 1;">
                    <OPDPackageSelectSpecialty  :tabName="'HOSPITALPACKAGEFLOW'"/>
                </div>
                <div id="AllDetails" class="allDetail" style="display: none;">
                    <div>{{$t("Customer.Appointments.All_details")}}</div>
                    <img src="https://s3iconimages.mymedicine.com.mm/DropdownIcon.svg" @click="showSelectRegion"/>
                </div> 
            <div style="width: 100%;">
                <div v-if="showPackageCompLoader" style="display: flex; flex: 1;  align-items:center; justify-content: center; height: 80vh">
                    <img src="https://s3iconimages.mymedicine.com.mm/packageSearchGif.gif" alt="" style="width: 250px; height: auto;">
                </div>
                <div v-else>
                    <div v-if="getPackageList.length == 0" style="display: flex; flex-direction: column; justify-content: center; align-items: center; gap: 4px; color: grey; height: 60vh;">
                        <img src="https://s3iconimages.mymedicine.com.mm/noContentFound.svg" alt="" style="width: 80px; height: 80px;">
                        {{$t("Customer.PackageAppointments.no_package_found")}}
                    </div>  
                
                    <div v-else style="display: flex; flex-direction: column; gap: 16px; padding-top: 16px;">
                    <div v-for="(item, index) in getPackageList" :key="index">
                        <ViewParticularOPDPackage :item="item.packageInfo" :showHospitalInfo="false"/>
                    </div>
                </div>
                </div>
                
                
                
            </div>
        </div>
    </main-container>
</template>

<script>
import axios from "axios";
import BodyContainer from '../../../templates/bodyContainer.vue';
import HeaderContainer from '../../../templates/headerContainerOPDBooking.vue';
import MainContainer from '../../../templates/mainContainer.vue';
import { axios_auth_instance_customer, axios_auth_instance_hba_customer } from '../../../utils/axios_utils';
import OPDPackageParticularHospitalHeader from '../componentsUsedInPages/OPDPackageViewHospitalPage/OPDPackageParticularHospitalHeader.vue';
import HospitalSkeletonLoader from '../../OPDBookingPages/componentUsedInPage/opdHomePage/hospitalSkeletonLoader.vue';
import OPDPackageSelectSpecialty from '../componentsUsedInPages/OPDPackageViewHospitalPage/OPDPackageSelectSpecialty.vue';
import ViewParticularOPDPackage from '../componentsUsedInPages/OPDPackageViewHospitalPage/viewParticularOPDPackage.vue';



export default {
  components: {BodyContainer, HeaderContainer,  MainContainer, OPDPackageParticularHospitalHeader, HospitalSkeletonLoader, OPDPackageSelectSpecialty, ViewParticularOPDPackage },
    name: 'ViewParticularHospitalOPDPackage',
    data: function() {
        return {
            token: null,
            todayAppointment: [],
            pageLoading: true,
            ongoingAppointment: [],
            bannerURL: '',
            scrollableDiv: null,
            searchMeDiv: null,
            allDetailsDiv: null,
            dependent_members: [],
            currentCustomer: null,
            doctorList: [],
            hospitalRegNumber: null,
            searchBoxDiv: null,
        };
    },
    computed: {
        showHospitalInfoLoader () {
            return this.$store.state.OPDPackageParticularHospitalState.fetchingHospitalInfo;        
        },
        showPackageCompLoader () {
            return this.$store.state.OPDPackageParticularHospitalState.fetchingData;        
        },
        getPackageList() {
            return this.$store.state.OPDPackageParticularHospitalState.shownPackageList || [];        
        }
    },
    async mounted() {
        if (!window.xm)
			this.token = this.$cookies.get('customerToken');
		else
			this.token = localStorage.getItem('customerToken');

        this.hospitalRegNumber = this.$route.params.hospitalRegNumber
        this.$store.dispatch('OPDPackageParticularHospitalState/loadHospitalInfo', {hospitalRegNumber: this.hospitalRegNumber});        
        this.$store.dispatch('OPDPackageParticularHospitalState/loadPackageList', {hospitalRegNumber: this.hospitalRegNumber});  

        this.userPageViewTable();
    },

    methods: {
        userPageViewTable() {
            if (this.token === null) {
                var userPageViewTableBody = {
                    pageName : 'PackageListingInHospital',
                    typeOfUser: 'CUSTOMER'
                }
            } else {
                var userPageViewTableBody = {
                    pageName: 'PackageListingInHospital',
                    typeOfUser: 'CUSTOMER',
                    token: this.token
                }
            }
            axios.post(process.env.VUE_APP_BACKEND_URL+"/userPageViewTable", userPageViewTableBody)
            .catch((userPageViewTableError) => {
                console.log('[Error][userPageTableError] Error in adding userPageViewTable', userPageViewTableError);
            });
        },
        async getDependentData() {
            this.currentCustomer = this.$cookies.get("customerToken");
                let customerData = {
                token: this.currentCustomer,
                typeOfUser: "CUSTOMER",
            };
            try {
                let dependentResponse = await axios_auth_instance_hba_customer.get("/user/dependents");
                var dependent_list = [];
                var dependent_members = dependentResponse.data.dependentList;
                this.dependent_members = dependent_members;
                this.dependent_members.push({
                    _id: 'Myself',
                    dependent_name: 'Myself',
                    NRC: this.NRC,
                });
            } catch (error) {
                console.log(error);
            } 
        },

        handler() {
            this.searchMeDiv.style.position = 'relative';
            this.searchMeDiv.style.top = 0;
            let rect = this.searchBoxDiv.getBoundingClientRect();
            let divTop = rect.top + window.scrollY;
            console.log(divTop)
            if(divTop < 10) {
            this.searchMeDiv.style.display = 'none';
            this.allDetailsDiv.style.display = 'flex'
            }
            // if (divTop <-100) {
            //     this.searchMeDiv.style.display = 'none';
            //     this.allDetailsDiv.style.display = 'flex'
            // } else {
            //     // Remove the class if the div is scrolled away from the top
            //     // myDiv.classList.remove('reachedTop');
            // }
            // console.log('Scrolling is happening inside the div!');

        },
        showSelectRegion() {
             this.scrollableDiv.removeEventListener('scroll', this.handler);
            this.searchMeDiv.style.display = 'block';
            this.searchMeDiv.style.position = 'sticky';

            this.allDetailsDiv.style.display = 'none'
            setTimeout(() => {
                this.scrollableDiv.addEventListener('scroll', this.handler);

            }, 1000)

        },
    },
};

</script>
<style  scoped>
.allDetail {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px 12px 16px;
    background: white;
    border-bottom: 1px solid #ebebeb;
    position: sticky;
    max-width: 425px;
    top: 0;
    z-index: 10;
    /* display: none; */
}
</style>